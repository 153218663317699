<template>
<v-row class="fill-height">
    <v-col>
        <v-sheet height="64">
            <v-toolbar flat>
                <v-btn fab text small color="grey darken-2" @click="$refs.calendar.prev()">
                    <v-icon small>
                        mdi-chevron-left
                    </v-icon>
                </v-btn>
                <v-btn fab text small color="grey darken-2" @click="$refs.calendar.next()">
                    <v-icon small>
                        mdi-chevron-right
                    </v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar">
                    {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-col lg="3" md="3" cols="6" class="s-col-form">
                    <c-select-area full clearable noDefault v-model="AreID" label="Área" v-if="$fun.isAdmin()" :typeArea="3" />
                </v-col>
            </v-toolbar>

        </v-sheet>
        <v-sheet height="auto">
            <v-calendar locale="es" ref="calendar" v-model="value" color="primary" @click:event="showEvent" :start="start" :end="end" @moved="moved">
                <template #day="{ past, date}">
                    <v-card style="padding-bottom:12px;" v-for="d in requiremennDay(date)" elevation="0" :key="d.ReqDocumentNumber">

                        <div v-for="m in d.items" :key="m.ReqDocumentNumber">

                        
                            <!--  -->
                            <!-- <v-hover v-slot="{ hover }" style="margin-top:20px"> :class="{ 'pt-1 pb-1 pr-2 pl-2 mb-2 on-hover': hover }"-->
                                <v-card dense outlined  style="border-radius: 10px;">

                                    <v-card-subtitle class="" style="position: relative;padding-bottom:0px !important;padding-top:5px !important;font-size:12px !important;">
                                        <b><a>{{m.ReqDocumentNumber}} - {{m.nameBusiness}}</a></b>
                                    </v-card-subtitle>

                                    <v-card-text class="position: relative; mt-2">
                                        
                                        <b>Solicitado por:</b> <br>{{m.UsrName}}
                                        <br><b>Area:</b> {{m.AreName}}
                                        <br><b>Prioridad:</b>
                                        <v-chip x-small :color="m.TypePriorityColor">
                                            {{ m.TypePriorityName }}
                                        </v-chip>
                                        <br>
                                        <v-chip x-small :color="m.ReqStatusColor"> {{m.ReqStatusName }}
                                            <v-btn fab x-small @click="openDialog(m)" elevation="0" :color="m.ReqStatusColor"><i class="fas fa-eye"></i></v-btn>
                                        </v-chip>
                                        <br>

                                    </v-card-text>

                                </v-card>
                            <!-- </v-hover> -->
                        </div>
                    </v-card>
                </template>
            </v-calendar>
        </v-sheet>
    </v-col>
    <v-dialog v-if="dialogEdit" v-model="dialogEdit" persistent width="100%">
        <v-card>
            <lgs-requirement-edit :value="parentID" @close="dialogEdit = false"></lgs-requirement-edit>
            <!-- <lgs-requirement-edit :value="RequirementEdit.ReqID" @close="dialog = false" :RequirementEdit="RequirementEdit" :TypeRequirement="1"></lgs-requirement-edit> -->
        </v-card>
    </v-dialog>

    <!-- <v-dialog v-if="processing" v-model="processing" width="400">
        <v-card color="primary" dark>
            <v-card-text>
                Por favor espere ...
                <v-progress-linear indeterminate color="white" class="mb-0 mt-2"></v-progress-linear>
            </v-card-text>

        </v-card>
    </v-dialog> -->
    <modal-loading :processing="processing" :messageProcessing="messageProcessing"></modal-loading>

</v-row>
</template>

<script>
import _sRequirement from "@/services/Logistics/RequirementService";
import ApprovalLevelsDocument from "@/views/Security/Circuit/Levels/ApprovalLevelsDocument";
import LgsRequirementEdit from "@/views/Logistics/Requirement/LgsRequirementEdit";
import _sGeneral from "@/services/HelperService.js";

export default {
    components: {
        ApprovalLevelsDocument,
        LgsRequirementEdit
    },
    data: () => ({

        processing: false,
        messageProcessing: "",

        dates: [],
        week: 0,
        value: null,
        title: "",
        dialog: false,
        events: [],
        start: null,
        end: null,
        typeOrder: 1,
        parentID: 0,
        dialogApproval: false,
        dialogEdit: false,
        AreID: null
    }),

    created() {
        if (!this.$fun.isAdmin()) this.AreID = this.$fun.getUserInfo().CtrContract.AreID;
        this.calendar(this.$moment(new Date()));
    },

    watch: {
        AreID() {
            if(this.AreID !== this.$fun.getUserInfo().CtrContract.AreID){
                this.calendar(this.$moment(new Date()));
            }
            
        }
    },

    methods: {

        openDialog(item) {
      
            this.parentID = item.ReqID
            this.dialogEdit = true
        },

        moved({
            date
        }) {
            this.calendar(date);
        },

        requiremennDay(date) {
   
            return this.dates.filter((x) => {
                return x.start === date;
            });
        },

        calendar(date) {

            this.dates = [];
            this.title = this.$moment(date)
                .format("MMMM")
                .toUpperCase();
            this.start = this.$moment(date).format("YYYY-MM-01");
            let days = this.$moment(this.start).daysInMonth();
            this.end = this.$moment(this.start)
                .add(days, "d")
                .format("YYYY-MM-DD");

            let obj = {
                searchText: "",
                dir: "",
                asc: "",
                length: -1,
                filter: {
                    TypeRequirement: 0, //this.typeOrder,
                    BeginDate: this.start,
                    EndDate: this.end,
                    ReqStatus: null,
                    AreID: this.AreID
                },
            };

            _sRequirement.pagination(obj, this.$fun.getUserID()).then((r) => {
              
                var daysInMonth = this.$moment().daysInMonth() + 6;
                let i = 0;
                while (i <= daysInMonth) {
                    i++;
                    var current = this.$moment(this.start).date(i).format("YYYY-MM-DD");

                    let date = {
                        start: current,
                        items: r.data.data.filter(
                            (x) => this.$moment(x.ReqDate).format("YYYY-MM-DD") == current
                        ),
                    };


                    this.dates.push(date);

                    
                }

            })

        },

        showEvent() {
            return this.dates;
        },
    },
}
</script>

<style>
.v-card--reveal {
    align-items: center;
    bottom: 0;
    left: 0;
    justify-content: center;
    opacity: 1;
    width: 100%;
    z-index: 99999;
}
</style>
